body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

/* body,
html {
  height: 100%;
} */

svg {
  vertical-align: baseline !important;
}

.ml-auto {
  margin-left: auto;
}

.spiner {
  margin: 20px 0;
  padding: 260px 50px;
  text-align: center;
}

/* Price range slider styles */
.ant-slider-wrapper > span.ant-menu-title-content {
  padding-left: 5px;
  padding-right: 5px;
}

.ant-checkbox-group * {
  font-size: 16px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.cart-table-img {
  max-width: 100%;
  height: 80px;
}

@media (max-width: 1394px) {
  .cart-table-img {
    width: 60px;
    height: auto;
  }
}
